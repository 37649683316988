import qs from "qs";
import request from "../utils/request";

export function getProfile() {
  return request({
    url: "/profile",
    method: "get",
  });
}

export function updateProfile(data) {
  const options = qs.stringify(data);

  return request({
    url: "/profile-update",
    method: "post",
    data: options,
  });
}
export function registerCode(data) {
  const options = qs.stringify(data);

  return request({
    url: "/registerCode",
    method: "post",
    data: options,
  });
}

export function getWinners(data) {
  const options = qs.stringify(data);

  return request({
    url: "/winners?" + options,
    method: "get",
  });
}

export function searchWinners(data) {
  const options = qs.stringify(data);

  return request({
    url: "/searchWinner",
    method: "post",
    data: options,
  });
}
export function getGallery(data) {
  const options = qs.stringify(data);

  return request({
    url: "/gallery?" + options,
    method: "get",
  });
}
export function getChecks(data) {
  const options = qs.stringify(data);

  return request({
    url: "/checks?" + options,
    method: "get",
  });
}

export function getPrizes() {
  return request({
    url: "/getPrizes",
    method: "get",
  });
}

export function logOut() {
  return request({
    url: "/logout",
    method: "post",
  });
}

export function deleteProfile() {
  return request({
    url: "/profile-delete",
    method: "post",
  });
}
export function sendFeedback(data) {
  return request({
    url: "/feedback",
    method: "post",
    headers: data?.headers,
    data: data.fields,
  });
}

export function uploadImage(data) {
  return request({
    url: "/photo",
    method: "post",
    headers: data?.headers,
    data: data.fields,
  });
}

export function vote(data) {
  const options = qs.stringify(data);

  return request({
    url: "/vote",
    method: "post",
    data: options,
  });
}

export function tax(data) {
  return request({
    url: "/address",
    method: "post",
    headers: data?.headers,
    data: data.fields,
  });
}
