<template>
  <section class="partners section" id="partners">
    <div class="section-title">где купить</div>
    <div class="container _small-width">
      <div class="partners__map map-container" id="map"></div>
      <div class="partners-list">
        <div
          class="partners-list__arrow visible-xs"
          v-if="active != 1"
          @click="active--"
        >
          <svg
            width="12"
            height="20"
            viewBox="0 0 12 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5 1L1.5 10L10.5 19"
              stroke="#E4C9B5"
              stroke-width="2"
            />
          </svg>
        </div>
        <div
          v-for="(item, i) in partners"
          :key="i"
          class="partners-list__item"
          :data-type="item.type"
          :class="{ visible: active === i + 1 }"
          @click="activeValue = item.value; curType=item.type; selectPoints()"
        >
          <div class="partners-list__item-title" v-html="item.name"></div>
        </div>
        <div
          class="partners-list__arrow visible-xs"
          v-if="active != partners.length"
          @click="active++"
        >
          <svg
            width="12"
            height="20"
            viewBox="0 0 12 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.5 1L10.5 10L1.5 19" stroke="#E4C9B5" stroke-width="2" />
          </svg>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  data: () => ({
    active: 1,
    activeValue: null,

    myMap: null,
    mapZoom: 12,

    curType: 0,

    partners: [
      {
        name: "Абрикос",
        value: "abricos",
        type: 1,
      },
      {
        name: "Хороший выбор",
        value: "horoshiy_vibor",
        type: 2,
      },
      {
        name: "SPAR",
        value: "spar",
        type: 3,
      },
      {
        name: "Fcity",
        value: "fcity",
        type: 4,
      },
    ],

    points: [
  	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. 30 лет Победы 7',
		lat: 56.472108,
		lng: 85.044200,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. 79 Гвардейской дивизии 12/1',
		lat: 56.509265,
		lng: 84.979790,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, Бела Куна 26/1',
		lat: 56.522386,
		lng: 85.046086,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Алтайская 118',
		lat: 56.480977,
		lng: 85.003650,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, Береговая 3/2',
		lat: 56.521716,
		lng: 84.931102,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Б.Подгорная 87',
		lat: 56.503448,
		lng: 84.954045,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Бирюкова 12',
		lat: 56.504248,
		lng: 85.037211,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Бирюкова 8',
		lat: 56.505570,
		lng: 85.035747,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, Богашевский тракт, 24',
		lat: 56.434790,
		lng: 84.993229,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, Ботанический переулок, 5а',
		lat: 56.454593,
		lng: 85.005195,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Вокзальная 41',
		lat: 56.503384,
		lng: 84.991262,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Грузинская 17',
		lat: 56.523324,
		lng: 85.026808,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, Дербышевский переулок, 17',
		lat: 56.503538,
		lng: 84.942933,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Иркутский 15',
		lat: 56.503508,
		lng: 84.977688,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, Иркутский тракт, 170',
		lat: 56.518553,
		lng: 85.064124,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, Иркутский тракт, 42',
		lat: 56.503920,
		lng: 85.009632,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, Иркутский тракт, 96',
		lat: 56.509677,
		lng: 85.030806,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, Иркутский тракт 74',
		lat: 56.505644,
		lng: 85.016648,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Карла Ильмера 13',
		lat: 56.507249,
		lng: 84.974032,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Киевская 109/1',
		lat: 56.462620,
		lng: 84.975380,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Киевская 13',
		lat: 56.479685,
		lng: 84.975362,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Киевская 68',
		lat: 56.470045,
		lng: 84.976341,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Коммунистический 143',
		lat: 56.588918,
		lng: 84.915408,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Королёва 1',
		lat: 56.474748,
		lng: 85.042268,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Красноармейская 101',
		lat: 56.462541,
		lng: 84.966010,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Красноармейская 101а стр.8',
		lat: 56.461282,
		lng: 84.963432,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Кулагина 6а',
		lat: 56.473664,
		lng: 85.006767,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Лазарева, 1',
		lat: 56.514565,
		lng: 85.046975,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Сергея Лазо 3Б',
		lat: 56.514172,
		lng: 85.027239,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Ленина 1а',
		lat: 56.599261,
		lng: 84.854664,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Льва Толстого 38/5',
		lat: 56.484491,
		lng: 85.001260,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, пр. Мира 27в',
		lat: 56.518304,
		lng: 84.979988,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, пр. Мира 76',
		lat: 56.526040,
		lng: 84.978039,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Мокрушина 24',
		lat: 56.449817,
		lng: 84.990786,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, Московский тракт 17',
		lat: 56.471511,
		lng: 84.941046,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Нарановича 2',
		lat: 56.491156,
		lng: 85.051260,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Нефтяная 1',
		lat: 56.452812,
		lng: 84.982458,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Новотрактовая 1',
		lat: 56.479262,
		lng: 84.887372,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Сибирская 104а',
		lat: 56.482667,
		lng: 85.006381,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Сибирская 60',
		lat: 56.482856,
		lng: 84.984300,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Сибирская 9',
		lat: 56.482443,
		lng: 84.966621,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Смирнова, 38',
		lat: 56.524452,
		lng: 84.988881,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Стрелочная 23',
		lat: 56.519953,
		lng: 84.990786,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Строителей 1',
		lat: 56.392816,
		lng: 85.084008,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Фрунзе 119',
		lat: 56.475747,
		lng: 85.002733,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Фрунзе 120',
		lat: 56.476418,
		lng: 84.985297,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Фрунзе 186',
		lat: 56.476438,
		lng: 85.000515,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Фрунзе 92а',
		lat: 56.476583,
		lng: 84.972379,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Центральная 15а',
		lat: 56.529828,
		lng: 85.034076,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Яковлева 35',
		lat: 56.493501,
		lng: 84.968930,
	  },
	  {
		type: 1,
		shop: 'Абрикос',
		address: 'г. Томск, ул. Яковлева 6',
		lat: 56.496597,
		lng: 84.969613,
	  },
	  {
		type: 2,
		shop: 'Хороший выбор',
		address: 'г. Новосибирск, ул. 1905 года, 19/1',
		lat: 55.041312,
		lng: 82.896939,
	  },
	  {
		type: 2,
		shop: 'Хороший выбор',
		address: 'г. Новосибирск, ул. 25 лет Октября, 22',
		lat: 55.075942,
		lng: 82.961231,
	  },
	  {
		type: 2,
		shop: 'Хороший выбор',
		address: 'г. Новосибирск, ул. Гидромонтажная, 44',
		lat: 54.857217,
		lng: 82.978326,
	  },
	  {
		type: 2,
		shop: 'Хороший выбор',
		address: 'г. Новосибирск, ул. Гоголя, 43/1',
		lat: 55.044361,
		lng: 82.939222,
	  },
	  {
		type: 2,
		shop: 'Хороший выбор',
		address: 'г. Новосибирск, ул. Громова, 15',
		lat: 54.935359,
		lng: 82.909587,
	  },
	  {
		type: 2,
		shop: 'Хороший выбор',
		address: 'г. Новосибирск, ул. Забалуева, 58',
		lat: 54.989290,
		lng: 82.815479,
	  },
	  {
		type: 2,
		shop: 'Хороший выбор',
		address: 'г. Новосибирск, ул. Комсомольская, 16',
		lat: 54.957991,
		lng: 82.962318,
	  },
	  {
		type: 2,
		shop: 'Хороший выбор',
		address: 'г. Новосибирск, ул. Шевченко, 34/1',
		lat: 55.022587,
		lng: 82.942205,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Новосибирск, ул. 1905 года, 69',
		lat: 55.044124,
		lng: 82.906820,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Новосибирск, ул. Бердское шоссе, 277',
		lat: 54.847690,
		lng: 83.063729,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Новосибирск, ул. Вилюйская, 17',
		lat: 54.999692,
		lng: 83.007602,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Новосибирск, ул. Выборная, 142/3',
		lat: 55.002516,
		lng: 83.020762,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Новосибирск, ул. Высоцкого, 39/5',
		lat: 55.033249,
		lng: 83.021158,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Новосибирск, Горский мкр, 64/1',
		lat: 54.992937,
		lng: 82.894675,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Новосибирск, ул. Каменская, 44',
		lat: 55.032991,
		lng: 82.928173,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Новосибирск, Карла Маркса площадь, 7',
		lat: 54.980332,
		lng: 82.897918,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Новосибирск, ул. Кирова, 108',
		lat: 55.012625,
		lng: 82.952392,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Новосибирск, ул. Кошурникова, 24/1',
		lat: 55.039331,
		lng: 82.970394,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Новосибирск, ул. Красный проспект, 101',
		lat: 55.055738,
		lng: 82.911914,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Новосибирск, ул. Кропоткина, 263',
		lat: 55.056744,
		lng: 82.931326,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Новосибирск, ул. Кубовая, 108/3',
		lat: 55.130230,
		lng: 82.891755,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Новосибирск, ул. Некрасова, 41',
		lat: 55.046197,
		lng: 82.927185,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Новосибирск, ул. Пархоменко, 21',
		lat: 54.986211,
		lng: 82.843830,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Новосибирск, ул. Плановая, 75/2',
		lat: 55.053211,
		lng: 82.894666,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Новосибирск, р.п. Кольцово, пр. Академика Сандахчиева, 9',
		lat: 54.940106,
		lng: 83.189897,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Новосибирск, ул. Титова, 248/1',
		lat: 54.981040,
		lng: 82.801969,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Томск, ул. Академический 13',
		lat: 56.471551,
		lng: 85.049814,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Томск, ул. Архитекторов 2',
		lat: 56.493988,
		lng: 85.056417,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Томск, ул. Гоголя 58',
		lat: 56.471964,
		lng: 84.961842,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Томск, ул. Калинина 145',
		lat: 56.589785,
		lng: 84.926044,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Томск, ул. Киевская 139',
		lat: 56.457418,
		lng: 84.975416,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Томск, ул. Кирова 65',
		lat: 56.461178,
		lng: 84.988423,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Томск, ул. Ленская 53',
		lat: 56.525410,
		lng: 84.973871,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Томск, ул. Нахимова, 13/1',
		lat: 56.454613,
		lng: 84.971041,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Томск, мкр. Солнечный 2а',
		lat: 56.477229,
		lng: 84.877194,
	  },
	  {
		type: 3,
		shop: 'SPAR',
		address: 'г. Томск, ул. Фрунзе 102',
		lat: 56.476394,
		lng: 84.980302,
	  },
	  {
		type: 4,
		shop: 'Fcity',
		address: 'г. Томск, ул. Беринга 10',
		lat: 56.516263,
		lng: 85.033680,
	  },
	  {
		type: 4,
		shop: 'Fcity',
		address: 'г. Томск, ул. Котовского 19/3',
		lat: 56.459721,
		lng: 84.965543,
	  },
	  {
		type: 4,
		shop: 'Fcity',
		address: 'г. Томск, ул. Курчатова 11а',
		lat: 56.593914,
		lng: 84.909578,
	  },
    ]
  }),
  methods: {
    afterChange(slideIndex) {},

    selectPoints() {
    	let t = this;

    	//console.log(t.curType);

    	t.myMap.geoObjects.removeAll();

    	let objectManager = new window.ymaps.ObjectManager({
            clusterize: true,
            gridSize: 32,
        });

        objectManager.clusters.options.set('preset', 'islands#blueClusterIcons');
        t.myMap.geoObjects.add(objectManager);

        var arr = new Array();

        t.points.forEach((el, idx) => {
        	//console.log(el, idx);
        
            if (t.curType != 0 && el.type != t.curType)
            	return;

	        arr.push({
				type: 'Feature',
				id: idx,
				geometry: {
					type: 'Point',
					coordinates: [el.lat, el.lng]
				},
				properties: {
					hintContent: el.shop+'<br>'+el.address,
				}
			});

		});

   	    objectManager.add({
    		type: "FeatureCollection",
    		features: arr
    	
    	});

    	t.myMap.setBounds(objectManager.getBounds(), {
        	checkZoomRange: true
        });
    },

    initMap() {

    	let t = this;

        t.myMap = new window.ymaps.Map('map',
            {
	            center: [55.863384, 37.430723],
                zoom: t.mapZoom,
                controls: []
            }, {
                searchControlProvider: 'yandex#search'
        });

        t.myMap.behaviors.disable('scrollZoom');

        t.myMap.controls.add('zoomControl');

        t.selectPoints();
    },
  },

  mounted() {

  	let t = this;

  	window.ymaps.ready(t.initMap);
  }
};
</script>

<style lang="scss" scoped>
.partners {
  padding-top: rem(40px);
  padding-bottom: rem(60px);
  z-index: 11;
  @media (orientation: landscape) {
    padding-top: rem(100px);
    padding-bottom: 0;
  }
  .container {
    position: relative;
  }
  &__map {
    height: rem(407px);
    border: rem(5px) solid $yellow;
    border-radius: rem(30px);
    overflow: hidden;
    text-transform: none;
    @media (orientation: landscape) {
      height: rem(680px);
      width: 100%;
      border: rem(5px) solid $yellow;
      border-radius: rem(30px);
    }
  }
  .section-title {
    @media (orientation: landscape) {
      margin-bottom: rem(130px);
    }
  }
  &-list {
    position: relative;
    margin-top: rem(22px);
    gap: rem(40px);
    @media (orientation: landscape) {
      position: absolute;
      top: 50%;
      right: rem(15px);
      transform: translate(50%, -50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0;
    }
    &__arrow {
      position: absolute;
      top: 0;
      width: rem(36px);
      height: rem(36px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &:first-child {
        left: 0;
      }
      &:last-child {
        right: 0;
      }
    }
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: rem(237px);
      height: rem(63px);
      text-align: center;
      font-size: rem(20px);
      color: #ffe6be;
      border-radius: rem(20px);
      background: #62463e;
      border: rem(2px) solid $yellow;
      cursor: pointer;
      @media (max-width: $md) {
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%, 0%);
        width: auto;
        height: rem(36px);
        padding: 0 rem(20px);
        font-size: rem(14px);
        border-radius: rem(10px);
        white-space: nowrap;
        opacity: 0;
        pointer-events: none;
        transition: 0.4s;
        &.visible {
          opacity: 1;
          pointer-events: all;
        }
      }
      &-content {
        @media (max-width: $md) {
          width: 100%;
          padding: rem(30px);
        }
      }
    }
  }
}
</style>
