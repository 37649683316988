<template>
  <div class="table_holder w-100">
    <div class="table--desktop table">
      <div class="table__header">
        <div class="table__header-row"></div>
      </div>
      <div class="table__inner">
        <table>
          <thead>
            <tr>
              <th v-for="(item, index) in thead" :key="index">
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody>
            <slot name="tbody"></slot>
          </tbody>
        </table>
      </div>
    </div>
    <div class="table--mobile table">
      <div class="table__inner">
        <slot name="mobile"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["thead"],
};
</script>

<style lang="scss">
.table_holder {
  overflow: auto;

  td.status-bad {
    color: #912982;
  }
  td.status-moderation {
    color: #fff;
  }
  .error_icon {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    width: rem(17px);
    height: rem(17px);
    font-size: rem(12px);
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(1, 63, 93, 0.15);
    border-radius: 50%;
  }
}
.table {
  width: 100%;
  margin: 0 auto;
  text-transform: none;
  &__inner {
  }
  table {
    width: 100%;
  }

  th {
    padding: rem(24px) rem(16px);
    font-size: rem(22px);
    line-height: 1;
    text-align: left;
    color: $light;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    .profile__checks & {
      border-top: none;
      font-size: rem(24px);
      border-bottom: rem(3px) solid #bca16c;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }

  &--desktop {
    display: none !important;
    max-width: 100%;
    width: 100%;
    margin: auto;
    td {
      position: relative;
      padding: rem(16px) rem(16px) rem(15px);
      font-family: $font-reg;
      font-size: rem(20px);
      color: #fff;
      .profile__checks & {
        text-transform: uppercase;
        font-size: rem(18px);
        font-family: $font-bold;
        &::before {
          display: none;
        }
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          height: 1px !important;
          background: rgba(#fcfcfc, 0.2) !important;
        }
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
      svg {
        display: block;
        margin-left: rem(9px);
        outline: none;
      }
      .check {
        @include size(rem(40px));
        object-fit: cover;
        margin-right: rem(12px);
        border-radius: rem(4px);
        cursor: pointer;
      }
    }
    tr {
      &:nth-child(even) {
        td {
          position: relative;
          z-index: 1;
          &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: #412d26;
            z-index: -1;
          }
          &:first-of-type {
            &::before {
              border-radius: rem(10px) 0 0 rem(10px);
            }
          }
          &:last-of-type {
            &::before {
              border-radius: 0 rem(10px) rem(10px) 0;
            }
          }
        }
      }
      &:last-of-type {
        td {
          &::after {
            display: none;
          }
        }
      }
    }
  }
  &--mobile {
    display: block;
    width: 100%;
    font-size: rem(16px);
    font-weight: 700;
    .table__inner {
      &::-webkit-scrollbar {
        width: rem(5px);
        opacity: 1;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        background: rgba(255, 227, 202, 0.5);
        border-radius: 25px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $red;
        border-radius: 10px;
        transition: all 0.3s ease-in-out;
      }
    }

    .table-block {
      display: flex;
      flex-direction: column;
      padding: rem(10px);
      border-radius: rem(6px);
      font-size: rem(14px);
      line-height: 1.78;
      font-family: $font-reg;
      font-weight: 400;
      &:nth-child(even) {
        background: #443127;
      }
      &:not(:last-child) {
        margin-bottom: rem(10px);
      }
    }
    tr:not(:last-of-type) {
      .table-mobile__inner {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
    .table-mobile__inner {
      width: 100%;
      padding: rem(16px) 0;
      tr {
        vertical-align: top;
        &:nth-child(odd) {
          td {
            position: relative;
            color: #fff;
            &::before {
              content: "";
              display: block;
              width: 120%;
              height: 100%;
              position: absolute;
              top: 0;
              background: #fff;
              transform: skew(-10deg);
              z-index: -1;
            }
            &:first-of-type {
              &::before {
                left: rem(10px);
              }
            }
            &:last-of-type {
              &::before {
                right: rem(10px);
              }
            }
          }
        }
        td {
          text-transform: uppercase;
          padding: rem(16px) 0;
          &:first-of-type {
            padding-right: rem(36px);
          }
          svg {
            display: block;
            width: rem(18px);
            margin-left: rem(6px);
            outline: none;
          }
          .check {
            @include size(rem(40px));
            object-fit: cover;
            margin-bottom: rem(12px);
            border-radius: rem(4px);
            cursor: pointer;
          }
        }
      }
    }
  }
  @media (orientation: landscape) {
    &--desktop {
      display: block !important;
    }
    &--mobile {
      display: none !important;
    }
    &__inner {
    }
  }
}
</style>
