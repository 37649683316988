<template>
  <form class="form__block">
    <inputText
      label="E-mail"
      :field="email"
      class="_big"
      @input="email = $event"
      type="email"
      :class="{ error: validationStatus($v.email) || errorMessage.email }"
      :error="
        (!$v.email.required && $v.email.$error
          ? 'Обязательное поле'
          : !$v.email.email && $v.email.$error
          ? 'Некорректный e-mail'
          : '') || errorMessage.email
      "
    />

    <div class="form__footer">
      <button
        type="button"
        class="btn btn--primary btn--loader-icon w-100"
        @click="submit()"
        :class="{ loading: submitStatus == 'PENDING' }"
      >
        Продолжить
        <img
          src="../../assets/images/icons/spinner.svg"
          class="loader"
          alt=""
        />
        <img
          class="btn__icon"
          src="../../assets/images/icons/arrow_1.svg"
          alt=""
        />
      </button>
    </div>
  </form>
</template>

<script>
import inputText from "@/components/form/inputText.vue";

import { required, email } from "vuelidate/lib/validators";

export default {
  name: "RecoverForm",
  data() {
    return {
      ready: false,
      index: 1,
      email: null,

      errorMessage: {
        email: null,
      },

      submitStatus: null,
    };
  },

  validations: {
    email: { required, email },
  },

  methods: {
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        login: null,
      };
    },

    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    showAuthorization() {
      this.$modal.hide("recover");
      this.$modal.show("authorization");
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        const errors = Object.keys(this.errorMessage);
        for (let index = 0; index < errors.length; index++) {
          const element = errors[index];
          if (this.$v[element].$error) {
            this.gtmEvent(element, "password_recovery", "step_error");
          }
        }
      }
      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";

        this.gtmEvent("", "password_recovery", "step1_success", "step_1");

        this.$store
          .dispatch("Recover", {
            login: this.email,
          })
          .then((r) => {
            if (!r.result) {
              this.submitStatus = null;

              let fieldsError = null;

              if (r.error && r.error.login) {
                [this.errorMessage.email] = r.error.login;
                this.gtmEvent("login", "password_recovery", "step_error");
                fieldsError = true;
              }

              if (r.error && !fieldsError) {
                this.$modal.show("common_error", {
                  title: r.error,
                });
              }
            } else {
              this.submitStatus = null;

              this.$modal.show("common_success", {
                title: "Ваш пароль восстановлен",
                text: `Пароль отправлен на вашу почту ${this.email}`,
                btnClose: true,
                successIcon: true,
              });
            }
          })
          .catch(() => {
            this.submitStatus = null;
          });
      }
    },
  },
  mounted() {},
  components: {
    inputText,
  },
};
</script>

<style lang="scss">
.recover {
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;
  }

  @media (orientation: landscape) {
    .btn {
    }
  }
}
</style>
