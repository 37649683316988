var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form"},[_c('div',{staticClass:"form__fields"},[_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.address_fio) || _vm.errorMessage.address_fio,
      },attrs:{"label":"ФИО","field":_vm.address_fio,"size":"lg","error":(!_vm.$v.address_fio.required && _vm.$v.address_fio.$error
          ? 'Обязательное поле'
          : !_vm.$v.address_fio.rus && _vm.$v.address_fio.$error
          ? 'Введите текст на русском языке'
          : '') || _vm.errorMessage.address_fio},on:{"input":function($event){_vm.address_fio = $event}}}),_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.phone) || this.errorMessage.phone,
      },attrs:{"label":"Телефон","field":_vm.phone,"size":"lg","type":"tel","mask":'+7(###) ###-##-##',"error":(!_vm.$v.phone.required && _vm.$v.phone.$error
          ? 'Обязательное поле'
          : !_vm.$v.phone.minLength && _vm.$v.phone.$error) || this.errorMessage.phone},on:{"input":function($event){_vm.phone = $event}}}),_c('inputText',{class:{
        error:
          _vm.validationStatus(_vm.$v.address_region) || _vm.errorMessage.address_region,
      },attrs:{"label":"Область","field":_vm.address_region,"size":"lg","error":_vm.errorMessage.address_region},on:{"input":function($event){_vm.address_region = $event}}}),_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.address_city) || _vm.errorMessage.address_city,
      },attrs:{"label":"Город","field":_vm.address_city,"size":"lg","error":(!_vm.$v.address_city.required && _vm.$v.address_city.$error
          ? 'Обязательное поле'
          : '') || _vm.errorMessage.address_city},on:{"input":function($event){_vm.address_city = $event}}}),_c('inputText',{class:{
        error:
          _vm.validationStatus(_vm.$v.address_street) || _vm.errorMessage.address_street,
      },attrs:{"label":"Улица","field":_vm.address_street,"size":"lg","error":(!_vm.$v.address_street.required && _vm.$v.address_street.$error
          ? 'Обязательное поле'
          : '') || _vm.errorMessage.address_street},on:{"input":function($event){_vm.address_street = $event}}}),_c('inputText',{class:{
        error:
          _vm.validationStatus(_vm.$v.address_house) || _vm.errorMessage.address_house,
      },attrs:{"label":"Дом","field":_vm.address_house,"size":"lg","error":(!_vm.$v.address_house.required && _vm.$v.address_house.$error
          ? 'Обязательное поле'
          : '') || _vm.errorMessage.address_house},on:{"input":function($event){_vm.address_house = $event}}}),_c('inputText',{class:{
        error:
          _vm.validationStatus(_vm.$v.address_block) || _vm.errorMessage.address_block,
      },attrs:{"label":"Корпус","field":_vm.address_block,"size":"lg","error":_vm.errorMessage.address_block},on:{"input":function($event){_vm.address_block = $event}}}),_c('inputText',{class:{
        error:
          _vm.validationStatus(_vm.$v.address_building) ||
          _vm.errorMessage.address_building,
      },attrs:{"label":"Строение","field":_vm.address_building,"size":"lg","error":_vm.errorMessage.address_building},on:{"input":function($event){_vm.address_building = $event}}}),_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.address_flat) || _vm.errorMessage.address_flat,
      },attrs:{"label":"Квартира","field":_vm.address_flat,"size":"lg","error":_vm.errorMessage.address_flat},on:{"input":function($event){_vm.address_flat = $event}}}),_c('inputText',{class:{
        error:
          _vm.validationStatus(_vm.$v.address_postal) || _vm.errorMessage.address_postal,
      },attrs:{"label":"Индекс","field":_vm.address_postal,"size":"lg","error":(!_vm.$v.address_postal.required && _vm.$v.address_postal.$error
          ? 'Обязательное поле'
          : '') || _vm.errorMessage.address_postal},on:{"input":function($event){_vm.address_postal = $event}}})],1),_c('div',{staticClass:"form__footer"},[_c('br'),_c('button',{staticClass:"btn btn--primary btn--loader-icon w-100",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"button"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" отправить"),_c('img',{staticClass:"loader",attrs:{"src":require("../../assets/images/icons/spinner.svg"),"alt":""}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }