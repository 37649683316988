import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Winners from "../views/Winners.vue";

import { isPortrait, isMobile, getOffset } from "../utils/device";

Vue.use(VueRouter);

const titlePrefix = "Продлите лето с Jardin";

const routes = [
  {
    path: "/",
    // redirect: "/coming-soon",
    name: "Home",
    component: Home,
    meta: {
      title: titlePrefix,
    },
  },
  {
    path: "/winners",
    name: "Winners",
    component: Winners,
    meta: {
      title: titlePrefix + "Победители",
    },
  },

  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/account/Profile.vue"),
    meta: {
      title: titlePrefix,
    },
  },
  {
    path: "/coming-soon",
    name: "Coming-soon",
    component: () => import("../views/Coming-soon.vue"),
    meta: {
      title: titlePrefix,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
        offset: {
          y: 50,
        },
      };
    }
    return { x: 0, y: 0 }; // Go to the top of the page if no hash
  },
});
export default router;
