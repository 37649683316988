<template>
  <div class="winners" id="winners">
    <div class="container _small-width">
      <div class="winners__header">
        <h2 class="section-title">Победители</h2>
        <!-- <div class="winners__retail">
          <img
            v-if="retailer"
            :src="require(`../assets/images/partners/logo-${retailer.logo}.png`)"
            class="winners__retail-img"
            alt=""
          />
          <div class="winners-select">
            <div class="winners-select__label _yellow">ВЫбор сети</div>
            <Select
              :options="[
                { id: 1, label: 'Макси', logo: 3 },
                { id: 2, label: 'Метрополис', logo: 4 },
                { id: 3, label: 'Линия', logo: 1 },
                { id: 4, label: 'Кировский', logo: 5 },
                { id: 5, label: 'Самбери', logo: 2 },
              ]"
              class="_golden"
              :field="retailer"
              @input="retailer = $event"
            />
          </div>
        </div> -->

        <div class="winners-prizes-nav">
          <div
            v-for="(nav, i) in prizes"
            :key="i"
            class="winners-prizes-nav__item"
            @click="setType(nav.value)"
          >
            <img
              :src="
                require(`../assets/images/prizes/prize-${nav.type}-small.png`)
              "
              alt=""
              class="winners-prizes-nav__img"
            />
            <span v-html="nav.name"></span>
          </div>
        </div>
      </div>
      <div class="winners-table-wrapper">
        <div v-if="winners && winners.length" class="winners__search">
          <form
            class="winners__search-form"
            v-on:submit.prevent="searchForm"
            :class="{
              error:
                validationStatus($v.searchQuery) ||
                this.errorMessage.searchQuery,
            }"
          >
            <input
              class="winners__search-field"
              type="text"
              name="term"
              v-model="searchQuery"
              @focus="errorReset()"
              placeholder="Поиск по e-mail "
            />
            <button type="submit" class="winners__search-button"></button>
            <div
              v-if="searchQuery"
              @click="formReset()"
              class="winners__search-clear"
            ></div>
            <div
              class="error-hint"
              v-if="
                validationStatus($v.searchQuery) ||
                this.errorMessage.searchQuery
              "
              v-html="
                (!$v.searchQuery.required && $v.searchQuery.$error
                  ? 'Обязательное поле'
                  : !$v.searchQuery.email && $v.searchQuery.$error
                  ? 'Некорректный e-mail'
                  : '') || this.errorMessage.searchQuery
              "
            ></div>
          </form>
        </div>

        <Table
          :thead="['Имя', 'E-mail', 'Приз']"
          class="winners-tab-desktop"
          v-if="winners && winners.length"
        >
          <template v-slot:tbody>
            <tr v-for="(item, index) in winners" :key="index">
              <td>
                {{ item.name }}
              </td>

              <td>
                {{ item.email }}
              </td>
              <td>
                {{ item.prize }}
              </td>
            </tr>
          </template>
          <template v-slot:mobile>
            <div
              v-for="(item, index) in winners"
              :key="index"
              class="table-block"
            >
              <span>{{ item.name }}</span>
              <span>{{ item.email }}</span>
              <span>{{ item.prize }}</span>
            </div>
          </template>
        </Table>
        <div class="no-results" v-if="!noResults && !winners.length">
          победители ещё не определены
        </div>
        <div class="no-results" v-if="noResults">Ничего не найдено</div>
      </div>

      <ul class="pagination" v-if="pages.length > 1">
        <li
          class="pagination-item"
          v-if="page != 1"
          @click="page--, paginate()"
        >
          <svg
            width="12"
            height="20"
            viewBox="0 0 12 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5 1L1.5 10L10.5 19"
              stroke="#E4C9B5"
              stroke-width="2"
            />
          </svg>
        </li>
        <li
          class="pagination-item"
          v-for="pageNumber in pages.length"
          :key="pageNumber"
          @click="(page = pageNumber), paginate()"
          :class="{
            current: page === pageNumber,
            last: pageNumber == pages.length && Math.abs(pageNumber - page) > 3,
            first: pageNumber == 1 && Math.abs(pageNumber - page) > 3,
            visible:
              Math.abs(pageNumber - page) < 3 ||
              pageNumber === pages.length ||
              pageNumber == 1,
          }"
        >
          {{ pageNumber }}
        </li>
        <li
          @click="page++, paginate()"
          v-if="page < pages.length"
          class="pagination-item"
        >
          <svg
            width="12"
            height="20"
            viewBox="0 0 12 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1.5 1L10.5 10L1.5 19" stroke="#E4C9B5" stroke-width="2" />
          </svg>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
// import Select from "@/components/form/inputSelect.vue";
import { required, email } from "vuelidate/lib/validators";

export default {
  components: {
    Table,
    // Select,
  },
  data: function () {
    return {
      retailIndex: 1,
      page: 1,
      perPage: 1,
      type: null,
      pages: [],
      searchQuery: null,
      date: null,
      total: 0,
      noResults: false,
      errorMessage: {
        date: null,
        searchQuery: null,
      },
      prizes: [
        {
          name: "Сертификат <br class='hidden-xs'>номиналом 10 000₽ <br>на&nbsp;авиабилеты",
          type: 2,
          value: 10
        },
        {
          name: "30&nbsp;000&nbsp;РУБЛЕЙ <br>на&nbsp;БАНКОВСКУЮ КАРТУ",
          type: 3,
          value: 11
        },
        {
          name: "Главный приз",
          type: 4,
          value: 12
        },
      ],
      winners: [],
    };
  },
  validations: {
    searchQuery: { required, email },
  },
  methods: {
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        searchQuery: null,
      };
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    setPages(total) {
      this.pages = [];
      if (total) {
        for (let index = 1; index <= total; index++) {
          this.pages.push(index);
        }
      }
    },
    paginate() {
      let page = this.page;
      this.$store
        .dispatch("GetWinners", {
          page: page,
          type: this.type,
        })
        .then((response) => {
          this.winners = response.data.items;
        });
    },
    searchForm() {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.page = 1;
      this.$store
        .dispatch("GetWinners", {
          email: this.searchQuery,
        })
        .then((response) => {
          this.winners = response.data.items;
          this.total = response.data.pages || 0;
          if (!this.winners.length) {
            this.noResults = true;
          } else {
            this.noResults = false;
          }
        });
    },
    setType(r) {
      this.type = r;
      this.page = 1;
      this.$store
        .dispatch("GetWinners", {
          type: this.type,
          page: 1,
        })
        .then((response) => {
          this.winners = response.data.items;
          this.total = response.data.pages || 0;
          if (!this.winners.length) {
            this.noResults = true;
          } else {
            this.noResults = false;
          }
        });
    },
    formReset() {
      this.$v.$reset();
      this.searchQuery = null;
      this.$store
        .dispatch("GetWinners", { page: this.page })
        .then((response) => {
          this.winners = response.data.items;
          this.total = response.data.pages || 0;
        });
    },
  },
  mounted() {},
  created() {
    this.$store
      .dispatch("GetWinners")
      .then((response) => {
        this.total = response.data.pages || 0;
        this.winners = response.data.items;
      });
  },
  watch: {
    winners() {
      this.setPages(this.total);
    },
    retailer(val) {
      this.$store
        .dispatch("GetWinners", { retailer: val.id, page: 1 })
        .then((response) => {
          this.total = response.data.pages || 0;
          this.winners = response.data.items;
        });
    },
  },
  computed: {
    // displayedWinners() {
    //   return this.paginate(this.winners);
    // },
  },
};
</script>

<style lang="scss">
.winners {
  position: relative;
  display: flex;
  padding: rem(70px) 0 rem(0px);
  align-items: center;
  z-index: 100;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: rem(-125px);
    right: rem(-190px);
    width: rem(320px);
    height: rem(400px);
    background: url("../assets/images/palm-3.svg") no-repeat center right;
    background-size: contain;
    z-index: -100;
    pointer-events: none;
  }
  &__title {
    margin-bottom: rem(20px);
  }
  .container {
    position: relative;
  }
  &__retail {
    display: flex;
    justify-content: center;
    align-items: center;
    &-img {
      width: rem(100px);
      height: rem(60px);
      margin-right: rem(30px);
      object-fit: contain;
    }
  }
  &-select {
    padding-top: rem(15px);
    &__label {
      text-align: center;
      font-size: rem(16px);
      margin-bottom: rem(20px);
    }
  }
  &-prizes-nav {
    display: flex;
    width: calc(100% + #{rem(30px)});
    justify-content: space-between;
    margin: rem(8px) rem(-15px) rem(25px);
    padding: 0 rem(20px);
    text-align: center;
    gap: 0 rem(40px);
    font-family: $font-reg;
    font-size: rem(10px);
    line-height: rem(12px);
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    &__item {
      flex-shrink: 0;
      cursor: pointer;
    }
    &__img {
      height: rem(130px);
      margin: 0 auto rem(10px);
    }
  }
  &__search-field {
    width: 100%;
    padding: 2px rem(20px) 0 rem(20px);
    height: rem(48px);
    background: #fce29d;
    border-radius: rem(30px);
    font-size: rem(16px);
    color: #0b0909;
    font-family: $font-reg;
    outline: none;
    &::placeholder {
      color: #0b0909;
    }
  }
  &__search-form {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: rem(0px) auto rem(20px);
  }
  &__search-button {
    position: absolute;
    top: 50%;
    right: rem(16px);
    transform: translateY(-50%);
    width: rem(20px);
    height: rem(21px);
    background: url("../assets/images/icons/search.svg") no-repeat center;
    background-size: contain;
  }
  &__search-clear {
    position: absolute;
    top: 0;
    right: rem(50px);
    width: rem(18px);
    height: 100%;
    background: url("../assets/images/icons/close.svg") no-repeat center;
    background-size: 100%;
    cursor: pointer;
  }
  &-table-wrapper {
    width: rem(1180px);
    max-width: 100%;
    padding: rem(16px);
    margin: auto;
    background: $brown_light;
    border: rem(2px) solid $gold;
    border-radius: rem(20px);
  }

  .no-results {
    text-align: center;
    color: #fff;
  }
  @media (min-width: $sm) {
    &-tab-desktop {
      display: block;
    }
    &-tab-mobile {
      display: none;
    }
  }
  @media (orientation: landscape) {
    padding: rem(115px) 0 rem(25px);
    &::before {
      display: none;
    }
    &__retail {
      &-img {
        width: rem(232px);
        height: rem(85px);
        margin-right: rem(60px);
      }
    }
    .section-title {
      margin-bottom: rem(45px);
    }
    &-table-wrapper {
      padding: rem(50px) rem(75px) rem(100px);
    }
    &-select {
      padding-top: 0;
      align-items: flex-start;
      &__label {
        font-size: rem(18px);
      }
    }
    &__title {
      margin-bottom: rem(40px);
    }
    &__search-form {
      margin: 0px auto rem(30px);
    }
    &__search-field {
      height: rem(63px);
      font-size: rem(20px);
    }
    &-prizes-nav {
      width: 60%;
      margin: rem(0px) auto rem(28px);
      padding: 0;
      font-size: rem(14px);
      line-height: rem(16px);
      justify-content: space-around;
      overflow: hidden;
      gap: 0;
      &__img {
        height: rem(140px);
        margin-bottom: rem(5px);
      }
    }
    .no-results {
      display: flex;
      align-items: center;
      justify-content: center;
      height: rem(300px);
      padding-top: rem(50px);
      font-size: rem(32px);
    }
  }
}
</style>
