<template>
  <section class="hero section" id="home">
    <div class="container">
      <div class="hero__row">
        <div class="hero__content">
          <div class="hero__suptitle">
            с&nbsp;2&nbsp;сентября по&nbsp;31&nbsp;октября 2024 года
          </div>
          <h1 class="hero__title">
            продлите <br class="hidden-xs" />лето <br />с JARDIN
          </h1>
          <img
            src="../assets/images/hero/products.png"
            alt=""
            class="hero__products visible-xs"
          />
          <button
            @click="setMetrika('register_receipt_1_screen'), toCheckUpload()"
            class="btn btn--primary btn--icon"
          >
            <span>Зарегистрировать чек</span>
            <img
              class="btn__icon"
              src="../assets/images/icons/arrow_1.svg"
              alt=""
            />
          </button>
          <!-- <div @click="openInNewTab()" class="btn btn--primary btn--icon">
            <span>ПЕРЕЙТИ В ЧАТ-БОТ</span>
            <img class="btn__icon" src="../assets/images/icons/tg.svg" alt="" />
          </div> -->
        </div>
      </div>
    </div>
    <img
      src="../assets/images/hero/products-desk.png"
      alt=""
      class="hero__products hidden-xs"
    />
  </section>
</template>

<script>
export default {
  components: {},
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
    openInNewTab() {
      this.setMetrika("bot");
      this.adhighChatBot();
      this.doGoalVK("chatbot");
      window.open("https://t.me/jardin_priz_bot", "_blank").focus();
    },
  },
  mounted() {
    // this.toCheckUpload()
  },
};
</script>
<style lang="scss" scoped>
.hero {
  position: relative;
  // height: calc(var(--vh, 1vh) * 100);
  // padding-bottom: rem(100px);
  padding-top: 0;
  z-index: 9;
  display: flex;
  align-items: flex-start;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(var(--vh, 1vh) * 107.5);
    background: url("../assets/images/hero/hero-mob.png") no-repeat center
      bottom;
    background-size: auto 100%;
  }
  .container {
    position: relative;
    padding-top: calc(var(--vh, 1vh) * 16);
    padding-bottom: rem(0px);
    height: 100%;
  }
  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__row {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: auto;
    flex-direction: column;
  }
  &__col {
    position: relative;
  }
  &__products {
    width: auto;
    height: calc(var(--vh, 1vh) * 32.8);
    object-fit: contain;
    margin: calc(var(--vh, 1vh) * 17.5) auto rem(10px) rem(15px);
  }
  &__suptitle {
    margin-bottom: calc(var(--vh, 1vh) * 3.7);
    text-transform: none;
    font-size: rem(18px);
    letter-spacing: 0.04em;
    text-shadow: 0px rem(4px) rem(4px) rgba(0, 0, 0, 0.25);
  }
  &__title {
    text-align: center;
    font-family: $font-bold;
    color: #21100e;
    font-size: rem(24px);
    line-height: rem(31px);
  }
  .btn {
    flex-shrink: 0;
    order: 1;
    width: 94%;
  }

  @media (orientation: portrait) {
    background-size: cover;
  }

  @media (orientation: landscape) {
    height: calc(var(--vh, 1vh) * 74.3);
    padding-top: 0;
    background: none;
    padding-bottom: 0;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(var(--vh, 1vh) * 117.5);
      background: url("../assets/images/hero/hero.png") no-repeat center bottom;
      background-size: auto 100%;
    }
    .container {
      padding-bottom: 0;
      display: flex;
      padding-top: vh(110px);
      height: 100%;
      align-items: flex-start;
    }
    &__content {
      margin: 0 auto 0;
    }
    &__row {
      max-width: 100%;
      flex-direction: row;
    }
    &__products {
      position: absolute;
      top: vh(50px);
      left: vw(20px);
      height: vh(618px);
    }
    &__suptitle {
      margin-bottom: vh(30px);
      font-size: vh(32px);
      line-height: 1;
      letter-spacing: 0.045em;
    }
    &__title {
      margin-bottom: vh(73px);
      text-transform: uppercase;
      font-size: vh(70px);
      line-height: vh(90px);
      color: #532110;
    }
    .btn {
      width: auto;
    }
  }
  @media (max-width: $xlg) {
  }
}
</style>
