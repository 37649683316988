var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',[_c('inputText',{class:{
      error: _vm.validationStatus(_vm.$v.newPassword) || _vm.errorMessage.newPassword,
    },attrs:{"label":"Новый пароль","type":"password","maxlength":"24","field":_vm.newPassword,"error":(!_vm.$v.newPassword.required && _vm.$v.newPassword.$error
        ? 'Обязательное поле'
        : !_vm.$v.newPassword.minLength && _vm.$v.newPassword.$error
        ? 'Минимум 6 символов'
        : !_vm.$v.newPassword.maxLength && _vm.$v.newPassword.$error
        ? 'Максимум 8 символа'
        : '') || _vm.errorMessage.newPassword},on:{"input":function($event){_vm.newPassword = $event}}}),_c('div',{staticClass:"change_password-footer"},[_c('button',{staticClass:"btn btn--primary btn--loader-icon w-100",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"button"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Отправить "),_c('img',{staticClass:"loader",attrs:{"src":require("../../assets/images/icons/spinner.svg"),"alt":""}}),_c('img',{staticClass:"btn__icon",attrs:{"src":require("../../assets/images/icons/arrow_1.svg"),"alt":""}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }