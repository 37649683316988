<template>
  <Modal
    name="change_password"
    size="lg"
    title="изменение пароля"
    @closeModal="closeModal"
  >
    <template v-slot:content>
      <ChangePasswordForm :token="token" />
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

import ChangePasswordForm from "@/components/form/ChangePasswordForm.vue";

export default {
  components: {
    Modal,
    ChangePasswordForm,
  },
  data: function () {
    return {
      token: null,
    };
  },

  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen(event) {
      this.token = event.params.token;
    },
  },
};
</script>

<style lang="scss" scoped></style>
