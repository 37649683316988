<template>
  <Modal
    name="success_signup"
    :success="true"
    :successIcon="true"
    size="lg"
    :title="title"
    @closeModal="closeModal"
    :withoutClosing="withoutClosing"
  >
    <template v-slot:content>
      <div class="success_modal">
        <div class="modal__subtitle">
          на указанный вами e-mail <br />отправлены данные для входа
        </div>
        <button
          @click="closeModal('success_signup')"
          class="success_modal-btn btn btn--primary w-100"
        >
          Хорошо
          <img
            class="btn__icon"
            src="../../assets/images/icons/arrow_1.svg"
            alt=""
          />
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      email: null,
      title: null,
      withoutClosing: null,
    };
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },

    beforeOpen(event) {
      console.log(event.params);
      if (event.params.title) {
        this.title = event.params.title || "Спасибо!";
      }
      this.email = event.params.email;
      this.withoutClosing = event.params.withoutClosing;
    },
  },
};
</script>
