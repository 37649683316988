<template>
  <header class="header">
    <div class="container">
      <div class="header__row">
        <router-link
          :to="{ name: 'Home' }"
          class="header__logo-wrapper"
          @click.native="showMenu = false"
        >
          <img
            src="@/assets/images/logo.svg"
            class="header__logo _left"
            alt=""
          />
        </router-link>
        <div class="header__nav">
          <router-link
            v-for="(item, idx) in navigation"
            :key="idx"
            class="header__nav-item hidden-xs"
            :to="{ name: item.path, hash: `#${item.hash}` }"
            :data-menuanchor="item.hash"
            @click.native="setMetrika('menu_head')"
          >
            {{ item.name }}
          </router-link>
        </div>
        <template v-if="!user">
          <div
            class="btn btn--bordered btn--sm header__login header__nav-item hidden-xs"
            @click="$modal.show('authorization')"
          >
            <img src="../assets/images/icons/user-2.svg" alt="" />
            <img src="../assets/images/icons/user.svg" alt="" />
            <span>Личный кабинет</span>
          </div>
          <div
            class="header__login header__nav-item visible-xs"
            @click="$modal.show('authorization')"
          >
            <img src="../assets/images/icons/user-3.svg" alt="" />
            <img src="../assets/images/icons/user-3.svg" alt="" />
          </div>
        </template>

        <template v-else>
          <div class="header__dropdown-wrapper">
            <router-link
              @click.native="gtmEvent('Личный кабинет', 'menu')"
              class="btn btn--bordered btn--sm header__login header__nav-item hidden-xs"
              :to="{ name: 'Profile' }"
            >
              <img src="../assets/images/icons/user-2.svg" alt="" />
              <img src="../assets/images/icons/user.svg" alt="" />
              <span class="hidden-xs">Личный кабинет</span>
            </router-link>
            <router-link
              @click.native="gtmEvent('Личный кабинет', 'menu')"
              class="header__login header__nav-item visible-xs"
              :to="{ name: 'Profile' }"
            >
              <img src="../assets/images/icons/user-3.svg" alt="" />
              <img src="../assets/images/icons/user-3.svg" alt="" />
            </router-link>
            <ul class="header__dropdown header__nav-info hidden-xs">
              <li class="header__dropdown-item" v-if="user.fio">
                <span>{{ user.fio }}</span>
              </li>
              <li class="header__dropdown-item" v-if="user.login">
                <span>{{ user.login }}</span>
              </li>
              <li class="header__dropdown-item" v-if="user.phone">
                <input
                  type="tel"
                  v-model="user.phone"
                  v-mask="'# ### ### ####'"
                />
              </li>
              <!-- <li
                class="header__dropdown-item header__nav-info-item _pointer"
                @click="showModal('recover')"
              >
                <img src="../assets/images/icons/lock.svg" alt="" />
                <span>Сменить пароль</span>
              </li> -->
              <li
                class="header__dropdown-item header__nav-info-item _pointer"
                @click="logout()"
              >
                <img src="../assets/images/icons/exit.svg" alt="" />
                <span>Выход</span>
              </li>
            </ul>
          </div>
        </template>

        <div class="header__hamburger visible-xs" @click="showBurger()">
          <img
            v-if="showMenu"
            src="../assets/images/icons/close-white.svg"
            alt=""
            class="header__hamburger-pic"
          />
          <img
            v-else
            src="../assets/images/hamburger_icon-white.svg"
            alt=""
            class="header__hamburger-pic"
          />
        </div>
      </div>
    </div>
    <div class="header__menu" :class="{ _active: showMenu }">
      <img
        @click="showBurger()"
        src="../assets/images/icons/close-white.svg"
        alt=""
        class="header__menu-close"
      />
      <div class="header__menu-wrapper">
        <router-link
          v-for="(item, idx) in navigation"
          :key="idx"
          class="header__menu-link"
          active-class="_active"
          @click.native="showBurger(), setMetrika('menu_head')"
          :to="{ name: item.path, hash: `#${item.hash}` }"
        >
          {{ item.name }}
        </router-link>
      </div>
      <div class="header__menu-bottom">
        <template v-if="!user">
          <div
            class="btn btn--bordered btn--sm header__menu-login"
            @click="showBurger(), $modal.show('authorization')"
          >
            <img src="../assets/images/icons/user-2.svg" alt="" />
            <img src="../assets/images/icons/user.svg" alt="" />
            <span>Личный кабинет</span>
          </div>
        </template>

        <template v-else>
          <router-link
            @click.native="showBurger()"
            class="btn btn--bordered btn--sm header__menu-login"
            :to="{ name: 'Profile' }"
          >
            <img src="../assets/images/icons/user-2.svg" alt="" />
            <img src="../assets/images/icons/user.svg" alt="" />
            <span>Личный кабинет</span>
          </router-link>
          <!-- <div class="header__menu-bottom-option" @click="showBurger(), showModal('recover')">
            <img src="../assets/images/icons/lock.svg" alt="">
            <span>Сменить пароль</span>
          </div> -->
          <div
            class="header__menu-bottom-option"
            @click="showBurger(), logout()"
          >
            <img src="../assets/images/icons/exit-2.svg" alt="" />
            <span>Выход</span>
          </div>
        </template>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      showMenu: false,
      navigation: [
        {
          name: "Главная",
          path: "Home",
          hash: "home",
        },
        {
          name: "призы",
          path: "Home",
          hash: "prizes",
        },
        {
          name: "где купить",
          path: "Home",
          hash: "partners",
        },
        {
          name: "победители",
          path: "Home",
          hash: "winners",
        },
        {
          name: "вопрос-ответ",
          path: "Home",
          hash: "faq",
        },
        // {
        //   name: "Где купить",
        //   path: "",
        // },
        // {
        //   name: "Snickers®️ 30 лет в движении 2.0",
        //   path: "Home",
        // },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    homePage() {
      if (this.$route.path === "/") {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    clickPrize() {
      if (this.$store.getters.user) {
        let apmButton = document.querySelector(
          "#apm-scan-qr .apm-action-button"
        );
        if (apmButton) apmButton.click();
      } else {
        this.$modal.show("authorization");
      }
    },

    gtm(label) {
      window?.dataLayer?.push({
        event: "interaction",
        site_section: "menu",
        action: "click",
        label,
      });
    },

    goTo() {
      this.$store.commit("SCROLL_TO_PRIZES", true);
      this.$router.push({ name: "Home" });
    },

    showModal(name) {
      this.$modal.show(name);
    },
    logout() {
      this.$store.dispatch("LogOut");
      document.location.reload();
    },
    showBurger() {
      this.showMenu = !this.showMenu;
    },
  },
  mounted() {
    // this.$store.dispatch("LogOut");
  },
};
</script>

<style scoped lang="scss">
.header {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  font-family: $font-reg;
  transition: 0.8s;
  z-index: 999999;
  &._modified {
    background: linear-gradient(to bottom, $brown, transparent);
  }

  &.header--mob_active {
    width: 100%;
  }
  .container {
    position: relative;
    padding-top: rem(13px);
    padding-bottom: rem(10px);
    z-index: 11;
  }
  &__row {
    display: flex;
    align-items: center;
    width: 100%;
  }
  &__5ka {
    margin-bottom: rem(-50px);
    img {
      height: rem(70px);
    }
  }
  &__logo {
    height: rem(60px);
    &-wrapper {
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
  &__hide-on_modified {
    transition: 0.4s;
    ._modified & {
      opacity: 0;
    }
  }
  &__show-on_modified {
    position: absolute;
    right: rem(0px);
    transform: translateY(-150%);
    transition: 0.4s;
    ._modified & {
      transform: translateY(0%);
    }
  }
  &__nav {
    position: relative;
    align-items: center;
  }
  &__hamburger {
    display: flex;
    align-items: center;
    font-size: rem(14px);
    margin-left: rem(15px);
    flex-shrink: 0;
    text-transform: uppercase;
    line-height: 1;
    z-index: 100;
    cursor: pointer;
    &-text {
      display: block;
      flex-shrink: 0;
      margin-left: rem(12px);
    }
    &-pic {
      width: rem(30px);
    }
  }

  &__menu {
    display: flex;
    position: absolute;
    width: 100vw;
    top: 0px;
    right: 0;
    height: 100vh;
    padding-top: vh(70px);
    padding-bottom: rem(20px);
    transform: translateY(-100%);
    transition: 0.5s;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
    background: linear-gradient(180deg, #20130e 0%, #5e402d 100%);
    opacity: 0.5;
    z-index: 12;
    &._active {
      transform: translateY(0%);
      opacity: 1;
    }
    &-close {
      position: absolute;
      top: rem(27px);
      right: rem(23px);
      width: rem(23px);
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: vh(7px);
    }
    &-link {
      font-family: $font-bold;
      font-size: rem(18px);
      margin-bottom: calc(var(--vh, 1vh) * 4.5);
    }
    &-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      &-option {
        display: flex;
        align-items: center;
        margin-bottom: rem(16px);
        font-family: $font-reg;
        font-size: rem(16px);
        text-transform: none;
        img {
          display: block;
          width: rem(20px);
          margin-right: rem(10px);
        }
      }
    }
    &-login {
      height: rem(48px);
      position: relative;
      padding-left: rem(46px);
      padding-right: rem(20px);
      margin-bottom: rem(29px);
      font-size: rem(16px);
      line-height: 1;
      color: $gold;
      border: rem(2px) solid $gold;
      img {
        position: absolute;
        left: rem(20px);
        top: 50%;
        transform: translateY(-50%);
        width: rem(18px);
        margin: 0;
        transition: 0.5s;
        &:first-of-type {
          opacity: 0;
        }
        &:last-of-type {
          display: block;
        }
      }
      &:hover {
        background: $gold;
        color: #fff;
        img {
          &:first-of-type {
            opacity: 1;
          }
          &:last-of-type {
            opacity: 0;
          }
        }
      }
    }
    &-img {
      position: absolute;
      object-fit: contain;
    }
  }
  &__login {
    display: flex;
    align-items: center;
    margin-left: auto;
    color: $gold;
    cursor: pointer;
    img {
      width: rem(45px);
      &:last-of-type {
        display: none;
      }
    }
  }
  &__dropdown-wrapper {
    margin-left: auto;
  }
  @media (orientation: landscape) {
    font-size: rem(16px);
    width: 100%;

    &__show-on_modified {
      right: rem(150px);
    }
    &__row {
      align-items: flex-start;
    }
    &__5ka {
      margin-bottom: 0;
      margin-right: rem(20px);
      img {
        height: rem(76px);
      }
    }
    &__nav {
      margin: rem(10px) auto 0;
    }
    &__nav-item {
      margin-left: rem(28px);
      color: #fff;
      font-size: rem(14px);
      transition: 0.4s;
      &:hover {
        color: $gold;
      }
      &:first-child {
        margin-left: 0;
      }
    }
    &__login {
      height: rem(40px);
      position: relative;
      padding-left: rem(46px);
      padding-right: rem(20px);
      margin-bottom: 0;
      font-size: rem(16px);
      line-height: 1;
      color: $gold;
      font-family: $font-bold;
      border: rem(2px) solid $gold;
      img {
        position: absolute;
        left: rem(20px);
        top: 50%;
        transform: translateY(-50%);
        width: rem(18px);
        margin: 0;
        transition: 0.5s;
        &:first-of-type {
          opacity: 0;
        }
        &:last-of-type {
          display: block;
        }
      }
      &:hover {
        background: $gold;
        color: #fff;
        img {
          &:first-of-type {
            opacity: 1;
          }
          &:last-of-type {
            opacity: 0;
          }
        }
      }
    }
    &__dropdown {
      position: absolute;
      top: 100%;
      right: rem(0px);
      min-width: rem(238px);
      display: block;
      padding: rem(20px) rem(30px);
      color: #000000;
      background: #fce29d;
      font-size: rem(16px);
      border: 1px solid #d0aa6f;
      border-radius: rem(10px);
      transition: 0.4s;
      opacity: 0;
      pointer-events: none;
      z-index: 100;
      cursor: default;
      & > * {
        pointer-events: none !important;
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 100%;
        right: 0;
        width: 100%;
        height: rem(16px);
      }
      &-item {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        font-size: rem(16px);
        line-height: 1.1;
        text-transform: none;
        &:not(:last-of-type) {
          margin-bottom: rem(18px);
        }
        &._yellow {
          color: #ffe8b4;
        }
        &._pointer {
          cursor: pointer;
        }
        strong {
          font-size: rem(22px);
          font-weight: 900;
        }
        input {
          border: none;
          background: none;
          outline: none;
          padding: 0;
          text-align: center;
          font-size: rem(16px);
          pointer-events: none;
          text-align: left;
          font-family: $font-reg;
        }
        img {
          width: rem(20px);
          margin-right: rem(10px);
        }
      }
      &-wrapper {
        position: relative;
        margin-left: 0;
        &:hover {
          & > .header__dropdown {
            opacity: 1;
            pointer-events: all;
            & > * {
              pointer-events: all !important;
            }
          }
        }
      }
    }
    &__logo {
      height: rem(123px);
      &-wrapper {
        margin-left: 0;
        margin-top: rem(10px);
      }
    }
    &__hamburger {
      margin-left: rem(70px);
      &-pic {
        width: rem(35px);
      }
      &-text {
        font-size: rem(14px);
        letter-spacing: 0.08em;
      }
    }
    &__menu {
      display: none;
      padding-top: vh(130px);
      align-items: flex-start;
      justify-content: center;
      &-link {
        margin-bottom: vh(17px);
        font-size: vh(28px);
        line-height: vh(56px);
      }
      .btn {
        padding: vh(0px) vh(23px);
        height: vh(56px);
        font-size: vh(20px);
      }
      &-img {
        height: 37vh;
        opacity: 0;
        transform: translateX(100%);
        transition: 1s;
        transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
        &--1 {
          top: 10vh;
          right: vh(-160px);
          transition-delay: 0.5s;
        }
        &--2 {
          top: 37vh;
          right: vh(130px);
          transition-delay: 0.8s;
        }
        &--3 {
          top: 60vh;
          right: vh(-30px);
          transition-delay: 1.1s;
        }
        ._active & {
          transform: translateX(0%);
          opacity: 1;
        }
      }
    }
    .container {
      display: flex;
      max-width: 91vw;
      padding-top: rem(33px);
      padding-bottom: rem(9px);
    }
  }
}
</style>
