var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"form"},[_c('inputText',{class:{
      error: _vm.validationStatus(_vm.$v.email) || _vm.errorMessage.email,
    },attrs:{"label":"Email","placeholder":"example@yandex.ru","type":"email","field":_vm.email,"autocomplete":"username","error":(!_vm.$v.email.required && _vm.$v.email.$error
        ? 'Обязательное поле'
        : !_vm.$v.email.email && _vm.$v.email.$error
        ? 'Некорректный e-mail'
        : '') || _vm.errorMessage.email},on:{"input":function($event){_vm.email = $event}}}),_c('inputText',{class:{
      error: _vm.validationStatus(_vm.$v.password) || this.errorMessage.password,
    },attrs:{"label":"Пароль","type":"password","field":_vm.password,"autocomplete":"current-password","error":(!_vm.$v.password.required && _vm.$v.password.$error
        ? 'Обязательное поле'
        : !_vm.$v.password.minLength && _vm.$v.password.$error
        ? 'Минимум ' + _vm.$v.password.$params.minLength.min + ' символов'
        : '') || this.errorMessage.password},on:{"input":function($event){_vm.password = $event}}}),_c('div',{staticClass:"form__row"},[_c('checkBox',{staticClass:"authorization__checkbox _center",class:{
        error: _vm.validationStatus(_vm.$v.checkbox) || this.errorMessage.checkbox,
      },attrs:{"label":"Запомнить меня","field":_vm.checkbox,"error":this.errorMessage.checkbox},on:{"input":function($event){_vm.checkbox = $event}}}),_c('div',{staticClass:"form__forget-link _undeline-link",on:{"click":_vm.switchToRecover}},[_c('span',{on:{"click":function($event){return _vm.gtmEvent('', 'password_recovery', 'password_recovery_start')}}},[_vm._v("Забыли пароль?")])])],1),_c('div',{staticClass:"form__footer"},[_c('button',{staticClass:"btn btn--primary btn--loader-icon w-100",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"button"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Войти "),_c('img',{staticClass:"loader",attrs:{"src":require("../../assets/images/icons/spinner.svg"),"alt":""}}),_c('img',{staticClass:"btn__icon",attrs:{"src":require("../../assets/images/icons/arrow_1.svg"),"alt":""}})]),_c('div',{staticClass:"form__another-option"},[_c('span',[_vm._v("Нет профиля? ")]),_c('div',{staticClass:"_undeline-link _yellow",on:{"click":_vm.showSignUpModal}},[_vm._v(" Зарегистрируйтесь ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }