<template>
  <div class="form">
    <div class="form__fields">
      <inputText
        label="ФИО"
        :field="address_fio"
        size="lg"
        :class="{
          error: validationStatus($v.address_fio) || errorMessage.address_fio,
        }"
        :error="
          (!$v.address_fio.required && $v.address_fio.$error
            ? 'Обязательное поле'
            : !$v.address_fio.rus && $v.address_fio.$error
            ? 'Введите текст на русском языке'
            : '') || errorMessage.address_fio
        "
        @input="address_fio = $event"
      />

      <inputText
        label="Телефон"
        :field="phone"
        size="lg"
        @input="phone = $event"
        type="tel"
        :mask="'+7(###) ###-##-##'"
        :class="{
          error: validationStatus($v.phone) || this.errorMessage.phone,
        }"
        :error="
          (!$v.phone.required && $v.phone.$error
            ? 'Обязательное поле'
            : !$v.phone.minLength && $v.phone.$error) || this.errorMessage.phone
        "
      />
      <inputText
        label="Область"
        :field="address_region"
        size="lg"
        :class="{
          error:
            validationStatus($v.address_region) || errorMessage.address_region,
        }"
        :error="errorMessage.address_region"
        @input="address_region = $event"
      />
      <inputText
        label="Город"
        :field="address_city"
        size="lg"
        :class="{
          error: validationStatus($v.address_city) || errorMessage.address_city,
        }"
        :error="
          (!$v.address_city.required && $v.address_city.$error
            ? 'Обязательное поле'
            : '') || errorMessage.address_city
        "
        @input="address_city = $event"
      />
      <inputText
        label="Улица"
        :field="address_street"
        size="lg"
        :class="{
          error:
            validationStatus($v.address_street) || errorMessage.address_street,
        }"
        :error="
          (!$v.address_street.required && $v.address_street.$error
            ? 'Обязательное поле'
            : '') || errorMessage.address_street
        "
        @input="address_street = $event"
      />
      <inputText
        label="Дом"
        :field="address_house"
        size="lg"
        :class="{
          error:
            validationStatus($v.address_house) || errorMessage.address_house,
        }"
        :error="
          (!$v.address_house.required && $v.address_house.$error
            ? 'Обязательное поле'
            : '') || errorMessage.address_house
        "
        @input="address_house = $event"
      />
      <inputText
        label="Корпус"
        :field="address_block"
        size="lg"
        :class="{
          error:
            validationStatus($v.address_block) || errorMessage.address_block,
        }"
        :error="errorMessage.address_block"
        @input="address_block = $event"
      />
      <inputText
        label="Строение"
        :field="address_building"
        size="lg"
        :class="{
          error:
            validationStatus($v.address_building) ||
            errorMessage.address_building,
        }"
        :error="errorMessage.address_building"
        @input="address_building = $event"
      />
      <inputText
        label="Квартира"
        :field="address_flat"
        size="lg"
        :class="{
          error: validationStatus($v.address_flat) || errorMessage.address_flat,
        }"
        :error="errorMessage.address_flat"
        @input="address_flat = $event"
      />
      <inputText
        label="Индекс"
        :field="address_postal"
        size="lg"
        :class="{
          error:
            validationStatus($v.address_postal) || errorMessage.address_postal,
        }"
        :error="
          (!$v.address_postal.required && $v.address_postal.$error
            ? 'Обязательное поле'
            : '') || errorMessage.address_postal
        "
        @input="address_postal = $event"
      />
    </div>

    <div class="form__footer">
      <br />
      <button
        type="button"
        class="btn btn--primary btn--loader-icon w-100"
        @click="submit()"
        :class="{ loading: submitStatus == 'PENDING' }"
      >
        отправить<img
          src="../../assets/images/icons/spinner.svg"
          class="loader"
          alt=""
        />
      </button>
    </div>
  </div>
</template>

<script>
import inputText from "@/components/form/inputText.vue";

import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
const rus = (value) => /^[А-ЯЁа-яё ]+$/.test(value);

export default {
  name: "SignupForm",
  props: ["network"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      ready: false,
      address_fio: null,
      phone: null,
      address_city: null,
      address_region: null,
      address_street: null,
      address_house: null,
      address_block: null,
      address_building: null,
      address_flat: null,
      address_postal: null,

      errorMessage: {
        address_fio: null,
        phone: null,
        address_city: null,
        address_region: null,
        address_street: null,
        address_house: null,
        address_block: null,
        address_building: null,
        address_flat: null,
        address_postal: null,
      },

      submitStatus: null,
    };
  },

  validations: {
    address_fio: { required, rus },
    phone: { required },
    address_postal: { required },
    address_city: { required },
    address_street: { required },
    address_house: { required },
  },
  watch: {
    checkbox(newVal) {
      if (!newVal) {
        this.$store.commit("SET_AGREE", false);
      }
    },
  },
  methods: {
    submitMedia(e, field) {
      this[`${field}Name`] = e;
      console.log(e[0]);
      this[field] = e[0];
    },
    deleteFile(e) {
      this[`${e}Name`] = {};
      this.attach = "";
    },
    selectSuggestion(suggestion) {
      this.city = suggestion.data.city;
    },
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        address_fio: null,
        phone: null,
        address_city: null,
        address_region: null,
        address_street: null,
        address_house: null,
        address_block: null,
        address_building: null,
        address_flat: null,
        address_postal: null,
      };
    },
    showRules() {
      if (!this.$store.getters.agree) {
        this.$modal.show("document_popup", {
          src: this.publicPath + "docs/rules.pdf",
        });
      }
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    setFormErrors(e) {
      const errors = e.error;
      this.submitStatus = null;

      let fieldsError = null;

      for (const [key, value] of Object.entries(errors)) {
        this.errorMessage[key] = value[0];
        fieldsError = true;
      }

      if (errors && !fieldsError) {
        this.$modal.show("common_error", {
          text: "Что-то пошло не так! " + errors,
        });
      }
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";
        const formData = new FormData();

        formData.append("address_fio", this.address_fio);
        formData.append("phone", this.phone);
        formData.append("address_city", this.address_city);
        formData.append("address_region", this.address_region);
        formData.append("address_street", this.address_street);
        formData.append("address_house", this.address_house);
        formData.append("address_block", this.address_block);
        formData.append("address_building", this.address_building);
        formData.append("address_flat", this.address_flat);
        formData.append("address_postal", this.address_postal);

        const data = {
          fields: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        this.$store
          .dispatch("Tax", data)
          .then((r) => {
            console.log(r);
            if (!r.result) {
              this.setFormErrors(r);
            } else {
              this.$modal.hide("delivery");
              this.$modal.show("common_success", {
                title: "Благодарим",
                successIcon: true,
                btnClose: true,
                text: "Ваши данные успешно приняты в&nbsp;работу и&nbsp;будут переданы в&nbsp;службу доставки. Курьер заранее свяжется по&nbsp;указанному в&nbsp;форме номеру телефона.<br><br>Вручение призов осуществляется до&nbsp;15&nbsp;августа 2024&nbsp;г.",
              });
              this.$store.dispatch("GetProfile");
            }
          })
          .catch((e) => {
            this.submitStatus = null;
            console.log(e);
          });
      }
    },
    showLogInModal() {
      this.$modal.hide("signup");
      this.$modal.show("authorization");
    },
  },
  mounted() {},
  components: {
    inputText,
  },
};
</script>
<style lang="scss"></style>
