var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feedback"},[_c('form',{key:_vm.indexForm,staticClass:"form"},[_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.name) || this.errorMessage.name,
      },attrs:{"label":"Имя","field":_vm.name,"error":(!_vm.$v.name.required && _vm.$v.name.$error
          ? 'Обязательное поле'
          : !_vm.$v.name.rus && _vm.$v.name.$error
          ? 'Введите текст на русском языке'
          : '') || this.errorMessage.name},on:{"input":function($event){_vm.name = $event}}}),_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.email) || this.errorMessage.email,
      },attrs:{"label":"E-mail","field":_vm.email,"error":(!_vm.$v.email.required && _vm.$v.email.$error
          ? 'Обязательное поле'
          : !_vm.$v.email.email && _vm.$v.email.$error
          ? 'Некорректный e-mail'
          : '') || this.errorMessage.email},on:{"input":function($event){_vm.email = $event}}}),_c('Select',{class:{
        error: _vm.validationStatus(_vm.$v.subject) || _vm.errorMessage.subject,
      },attrs:{"labelText":"Тема обращения","options":[
        { id: 1, label: 'Регистрация в акции' },
        { id: 2, label: 'Регистрация чека' },
        { id: 3, label: 'Призовой фонд' },
        { id: 4, label: 'Вопросы по правилам' },
        { id: 5, label: 'Технические вопросы' },
        { id: 6, label: 'Другое' },
      ],"placeholder":"Тема","error":(!_vm.$v.subject.required && _vm.$v.subject.$error
          ? 'Обязательное поле'
          : '') || _vm.errorMessage.subject},on:{"input":function($event){_vm.subject = $event.id}}}),_c('textArea',{class:{
        error: _vm.validationStatus(_vm.$v.message) || this.errorMessage.message,
      },attrs:{"label":"Текст обращения","field":_vm.message,"error":(!_vm.$v.message.required && _vm.$v.message.$error
          ? 'Обязательное поле'
          : '') || this.errorMessage.message},on:{"input":function($event){_vm.message = $event}}}),_vm._v(" "),_c('File',{attrs:{"chosenFileName":_vm.chosenFileName},on:{"submitMedia":_vm.submitMedia,"deleteFile":_vm.deleteFile}}),_c('div',{staticClass:"form__checkboxes"},[_c('checkBox',{class:{
          error: _vm.validationStatus(_vm.$v.checkbox2) || _vm.errorMessage.checkbox2,
        },attrs:{"label":`Соглашаюсь с&nbsp;<a href='${_vm.publicPath}docs/rules.pdf' class='_undeline-link _yellow' target='_blank'>Правилами участия</a> и&nbsp;<a href='${_vm.publicPath}docs/terms_of_use.pdf' class='_undeline-link _yellow' target='_blank'>обработкой персональных данных</a>`,"field":_vm.checkbox2,"error":_vm.errorMessage.checkbox2},on:{"input":function($event){_vm.checkbox2 = $event}}})],1),_c('div',{staticClass:"form__footer"},[_c('button',{staticClass:"btn btn--primary btn--loader-icon w-100",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"button"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Отправить "),_c('img',{staticClass:"loader",attrs:{"src":require("../../assets/images/icons/spinner.svg"),"alt":""}}),_c('img',{staticClass:"btn__icon",attrs:{"src":require("../../assets/images/icons/arrow_1.svg"),"alt":""}})])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }