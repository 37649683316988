<template>
  <section class="prizes section" id="prizes">
    <div class="container">
      <div class="prize prize--1">
        <div class="prize__title">гарантированные призы</div>
        <img
          src="../assets/images/prizes/prize-1.png"
          alt=""
          class="prize__img hidden-xs"
        />
        <img
          src="../assets/images/prizes/prize-1-small.png"
          alt=""
          class="prize__img visible-xs"
        />
        <div class="prize__text">
          пополнение баланса номера <br />мобильного телефона
        </div>
      </div>
      <div class="prizes__row">
        <div class="prize prize--2">
          <div class="prize__title">ЕЖЕНЕДЕЛЬНЫЙ <br />РОЗЫГРЫШ</div>
          <img
            src="../assets/images/prizes/prize-2.png"
            alt=""
            class="prize__img hidden-xs"
          />
          <img
            src="../assets/images/prizes/prize-2-small.png"
            alt=""
            class="prize__img visible-xs"
          />
          <div class="prize__text">
            Сертификат номиналом 10 000₽ <br />на&nbsp;авиабилеты
          </div>
        </div>
        <div class="prize prize--3">
          <div class="prize__title">СПЕЦИАЛЬНЫЙ <br />РОЗЫГРЫш</div>
          <img
            src="../assets/images/prizes/prize-3.png"
            alt=""
            class="prize__img hidden-xs"
          />
          <img
            src="../assets/images/prizes/prize-3-small.png"
            alt=""
            class="prize__img visible-xs"
          />
          <div class="prize__text">
            30 000₽ на&nbsp;БАНКОВСКУЮ КАРТУ <br />ЗА&nbsp;РЕГИСТРАЦИЮ 3&nbsp;И
            БОЛЕЕ ЧЕКОВ
          </div>
        </div>
      </div>
      <div class="prizes__row">
        <div class="prize prize--4">
          <div class="prize__title">главный приз</div>
          <img
            src="../assets/images/prizes/prize-4.png"
            alt=""
            class="prize__img hidden-xs"
          />
          <img
            src="../assets/images/prizes/prize-4-small.png"
            alt=""
            class="prize__img visible-xs"
          />
          <img
            src="../assets/images/prizes/birds.svg"
            alt=""
            class="prize__birds"
          />
          <div class="prize__text">
            сертификат в&nbsp;турагенство <br class="visible-xs" />на&nbsp;200
            000₽
          </div>
          <button @click="toCheckUpload(), setMetrika('register_receipt_2_screen')" class="btn btn--primary btn--icon">
            <span>Зарегистрировать чек</span>
            <img
              class="btn__icon"
              src="../assets/images/icons/arrow_1.svg"
              alt=""
            />
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.prizes {
  position: relative;
  padding-top: rem(60px);
  z-index: 10;
  &::before,
  &::after {
    content: "";
    position: absolute;
    display: none;
  }
  &::before {
    top: -12%;
    right: rem(-110px);
    width: rem(400px);
    height: rem(550px);
    background: url("../assets/images/cloud.svg") no-repeat center right;
    background-size: contain;
  }
  &::after {
    top: 10%;
    left: 0;
    width: rem(300px);
    height: rem(600px);
    background: url("../assets/images/palm.svg") no-repeat center right;
    background-size: contain;
  }
  @media (orientation: landscape) {
    &::before {
      display: block;
      top: rem(785px);
      right: rem(-345px);
      width: rem(800px);
      height: rem(800px);
    }
    &::after {
      display: block;
      top: rem(210px);
      left: rem(-275px);
      width: rem(868px);
      height: rem(978px);
    }
  }
  &__row {
    margin: auto;
    display: flex;
    flex-direction: column;
    @media (orientation: landscape) {
      width: 94%;
      flex-direction: row;
      justify-content: center;
    }
  }
}
.prize {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  &--1 {
    margin-bottom: rem(70px);
    @media (orientation: landscape) {
      margin-bottom: rem(40px);
    }
  }
  &--2 {
    margin-bottom: rem(70px);
    @media (orientation: landscape) {
      width: 50%;
      margin-bottom: 0;
    }
  }
  &--3 {
    margin-bottom: rem(45px);
    @media (orientation: landscape) {
      width: 50%;
      margin-bottom: 0;
    }
  }
  &--4 {
    @media (orientation: landscape) {
      margin-top: rem(121px);
    }
  }
  &__title {
    width: 90%;
    margin: auto;
    margin-bottom: rem(35px);
    font-size: rem(26px);
    line-height: rem(31px);
    color: $light;
    text-align: center;
    .prize--2 & {
      margin-bottom: rem(17px);
    }
    .prize--3 & {
      margin-bottom: rem(15px);
    }
    .prize--4 & {
      margin-bottom: rem(25px);
    }
    @media (orientation: landscape) {
      width: 100%;
      margin-bottom: rem(-5px);
      font-size: rem(50px);
      line-height: rem(65px);
      .prize--1 & {
        margin-bottom: rem(35px);
      }
      .prize--2 & {
        margin-bottom: rem(-5px);
      }
      .prize--3 & {
        margin-bottom: rem(-5px);
      }
      .prize--4 & {
        margin-bottom: rem(-5px);
      }
    }
  }
  &__subtitle {
    margin-bottom: rem(10px);
    color: $yellow;
    font-size: rem(20px);
    line-height: rem(24px);
    @media (orientation: landscape) {
      margin-bottom: rem(12px);
      font-size: rem(24px);
    }
  }
  &__text {
    font-family: $font-reg;
    font-size: rem(16px);
    line-height: rem(22px);
    .prize--4 & {
    }
    @media (orientation: landscape) {
      font-size: rem(18px);
      line-height: rem(22px);
      .prize--4 & {
        margin-bottom: rem(45px);
        font-size: rem(24px);
        line-height: 1;
      }
    }
  }
  &__img {
    max-width: 100%;
    margin-bottom: rem(15px);
    .prize--1 & {
      height: rem(300px);
    }
    .prize--2 & {
      height: rem(240px);
      margin-bottom: rem(10px);
    }
    .prize--3 & {
      height: rem(245px);
      margin-bottom: rem(15px);
    }
    .prize--4 & {
      margin-bottom: rem(5px);
    }
    @media (orientation: landscape) {
      height: rem(412px);
      margin-bottom: rem(37px);
      .prize--1 & {
        height: rem(350px);
        margin-bottom: rem(27px);
      }
      .prize--2 &,
      .prize--3 & {
        height: rem(412px);
        margin-bottom: rem(-63px);
      }
      .prize--4 & {
        height: rem(470px);
        margin-top: rem(75px);
        margin-bottom: rem(55px);
      }
    }
  }
  .btn {
    margin-top: rem(20px);
    @media (orientation: landscape) {
      margin-top: 0;
    }
  }
  &__birds {
    position: absolute;
    top: 20%;
    left: 90%;
  }
}
</style>
