import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import "./assets/styles/main.scss";
import VModal from "vue-js-modal";
import typograf from "./utils/filters";
import vSelect from "vue-select";
import Vuelidate from "vuelidate";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import VueTheMask from "vue-the-mask";
import "vue-select/src/scss/vue-select.scss";
import VTooltip from "v-tooltip";
import VueYandexMetrika from "vue-yandex-metrika";

Vue.prototype.$promoTools = window.promoTools;

Vue.use(VTooltip, {
  defaultTrigger: window.innerWidth > 768 ? "hover focus click" : "click",
});
Vue.filter("text", typograf);
Vue.component("v-select", vSelect);
Vue.use(VueTheMask);
Vue.use(VModal);
Vue.use(Vuelidate);
Vue.use(VueYandexMetrika, {
  id: 98186014,
  router: router,
  env: process.env.NODE_ENV,
  debug: true,
});

function initWidget() {
  const BASE_API = `${process.env.VUE_APP_URL}backend/api`;
  let checksID = store.getters?.checksIndex;
  if (window.promoTools)
    window.promoTools.checkGrabberWidget.settings = {
      skin: "default",
      events: {
        onSentSuccess(res) {
          store.dispatch("GetProfile");
          checksID++;
          store.commit("SET_CHECK_UPDATED", checksID);
          store.commit("SET_CHECK_SUCCESS", true);
          localStorage.setItem("checkUploaded", true);
        },
        onSentError(res) {},
        onUseQr() {},
        onUsePhotos() {},
        onUseManual() {
          // setTimeout(() => {
          //   const checkInfo = document.getElementById("check-info");
          //   checkInfo.addEventListener("click", function () {
          //     t.$modal.show("image", {
          //       src: require("@/assets/images/instruction.png"),
          //     });
          //   });
          // }, 2000);
        },
      },
      api: BASE_API,
      method: "/check",
      userUuid: store.getters?.user?.uuid,
      i18n: {
        qr: {
          title: "Сканирование",
          subTitle: "Наведите камеру на QR-код",
          btnQr: "Сканировать QR-код чека",
          btnManual: "Ввести данные вручную",
          btnPhotos: "Загрузить фото чека",
          cameraError: {
            title:
              "<strong>нет доступа к&nbsp;камере</strong>Мы&nbsp;не&nbsp;можем получить доступ к&nbsp;камере устройства. <br>Разрешите браузеру доступ к&nbsp;камере или введите данные с&nbsp;чека вручную.",
          },
        },
        photos: {
          title: "Загрузка чека",
          subTitle: "Добавьте фото чека",
          btnPhotos: "Отправить",
        },
        manual: {
          title: "ввести данные с&nbsp;чека вручную",
          subTitle: "",
          btnManual: "Отправить",
        },
        success: {
          title: "ваш чек успешно ЗАГРУЖЕН",
          subTitle:
            "Проверка чека может занимать <br>до&nbsp;72&nbsp;часов. После того, как чек <br>пройдет модерацию, вы&nbsp;получите письмо на&nbsp;почту, указанную при регистрации",
        },
        pending: {
          title: "Подождите!",
          subTitle: "Идет отправка чека",
        },
        fail: {
          title: "Не удалось отправить чек!",
        },
      },
    };
}
/* eslint-disable */
Vue.mixin({
  methods: {
    setMetrika(i) {
      if (window.ym) {
        window.ym(98186014, "reachGoal", i);
      }
    },
    doGoalVK(i) {
      _tmr.push({ type: 'reachGoal', id: 3400000, goal: i});
    },
    gtmEvent: (action, label) => {
      window.dataLayer?.push({
        event: "gaEvent",
        eventCategory: "internal",
        eventAction: action || "",
        eventLabel: label || "",
      });
    },
    adhighRegistration: () => {
      if (typeof __GetI === "undefined") {
        __GetI = [];
      }
      (function () {
          var p = {
              type: "CONVERSION",
              /* config START */
              site_id: "0000",
              order:[],
              transaction_id: "",
              revenue: "",
              pixel_id: "Conv_Zaregistr"
              /* config END */
          };
          __GetI.push(p);
          var domain = (typeof __GetI_domain) == "undefined" ? "px.adhigh.net" : __GetI_domain;
          var src = ('https:' == document.location.protocol ? 'https://' : 'http://') + domain + '/t.js';
          var script = document.createElement( 'script' );
          script.type = 'text/javascript';
          script.src = src;
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(script, s);
      })();
    },
    adhighChatBot: () => {
      if (typeof __GetI === "undefined") {
        __GetI = [];
      }
      (function () {
          var p = {
              type: "CONVERSION",
              /* config START */
              site_id: "0000",
              order:[],
              transaction_id: "",
              revenue: "",
              pixel_id: "Conv_ChatBot"
              /* config END */
          };
          __GetI.push(p);
          var domain = (typeof __GetI_domain) == "undefined" ? "px.adhigh.net" : __GetI_domain;
          var src = ('https:' == document.location.protocol ? 'https://' : 'http://') + domain + '/t.js';
          var script = document.createElement( 'script' );
          script.type = 'text/javascript';
          script.src = src;
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(script, s);
      })();

    },
    adhighRegistrationSuccess: () => {
      if (typeof __GetI === "undefined") {
        __GetI = [];
      }
      (function () {
          var p = {
              type: "CONVERSION",
              /* config START */
              site_id: "0000",
              order:[],
              transaction_id: "",
              revenue: "",
              pixel_id: "Conv_Otpr_Reg"
              /* config END */
          };
          __GetI.push(p);
          var domain = (typeof __GetI_domain) == "undefined" ? "px.adhigh.net" : __GetI_domain;
          var src = ('https:' == document.location.protocol ? 'https://' : 'http://') + domain + '/t.js';
          var script = document.createElement( 'script' );
          script.type = 'text/javascript';
          script.src = src;
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(script, s);
      })();

    },
    adhighCheck: () => {
      if (typeof __GetI === "undefined") {
        __GetI = [];
      }
      (function () {
          var p = {
              type: "CONVERSION",
              /* config START */
              site_id: "0000",
              order:[],
              transaction_id: "",
              revenue: "",
              pixel_id: "Conv_Zareg_Check"
              /* config END */
          };
          __GetI.push(p);
          var domain = (typeof __GetI_domain) == "undefined" ? "px.adhigh.net" : __GetI_domain;
          var src = ('https:' == document.location.protocol ? 'https://' : 'http://') + domain + '/t.js';
          var script = document.createElement( 'script' );
          script.type = 'text/javascript';
          script.src = src;
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(script, s);
      })();

    },
    adhighCheckSeccess: () => {
      if (typeof __GetI === "undefined") {
        __GetI = [];
      }
      (function () {
          var p = {
              type: "CONVERSION",
              /* config START */
              site_id: "0000",
              order:[],
              transaction_id: "",
              revenue: "",
              pixel_id: "Conv_Otpr_Check"
              /* config END */
          };
          __GetI.push(p);
          var domain = (typeof __GetI_domain) == "undefined" ? "px.adhigh.net" : __GetI_domain;
          var src = ('https:' == document.location.protocol ? 'https://' : 'http://') + domain + '/t.js';
          var script = document.createElement( 'script' );
          script.type = 'text/javascript';
          script.src = src;
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(script, s);
      })();

    },
    toCheckUpload(isProfile) {
      const t = this;
      if (new Date() >= new Date('2025-08-01')) {
        this.$modal.show("temporarily_unavailable");
      } else {
        // t.adhighCheck();
        if (!isProfile) {
          // t.doGoalVK('check_registration');
        }
        
        localStorage.setItem('checkUploaded', false);
        initWidget();
        if (this.$store.getters.user) {
          window.promoTools.checkGrabberWidget.show();
          
          setTimeout(() => {
            let close = document.querySelector(
              "#promo-tools .popup__close"
            );
            close.addEventListener("click", function () {
              if (localStorage.getItem('checkUploaded') != true) {
                t.setMetrika('left_receipt_registration');
              }
              
            });
          }, 1000);
        } else {
          this.$modal.show("authorization");
        }
      }
      // this.$modal.show("temporarily_unavailable");
      // window.promoTools.checkGrabberWidget.show();
    },
  },
});
 
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
